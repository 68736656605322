import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://41c0eb51daf1a1d0c9d4b8f50ffa4191@o4508109755187200.ingest.us.sentry.io/4508109759905792",
  debug: false,
  environment: "production",
  release: "ea6f476e3dc746088c2354aa478587e376fe02fc",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});